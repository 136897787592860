// import { renderToString } from 'react-dom/server';
import React, { useCallback } from "react";
import { Modal, Button, Select, DatePicker } from "antd";
import protobuf from "protobufjs";
import moment from "moment";
import _ from "lodash";
import "../Marker.css";
const { RangePicker } = DatePicker;
const { Option } = Select;

let pb = protobuf.parse(`
syntax = "proto3";
package ih;

message Levels {
    int64 granularity = 1;
    required DiffSet ts = 2;
    required DiffSet level = 3;
    required DiffSet max = 4;
    required DiffSet min = 5;
    required DiffSet first = 6;
    required DiffSet last = 7;
}
message DiffSet {
    repeated sint64 data = 1;
    int64 precision = 2;
}
`);

var Levels = pb.root.lookupType("ih.Levels");

const Exporter = ({ location, zoom, span }) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [granularity, setGranularity] = React.useState("1d");
  const [range, setRange] = React.useState([
    moment.unix(span[0]),
    moment.unix(span[1]),
  ]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    let [levels, temps] = await Promise.all([
      fetchLevels(location, range),
      fetchTemps(location, range),
    ]);
    console.log(levels, temps);
    let rows;
    if (temps && temps.granularity && temps.granularity > 2 * 3600) {
      levels = _.zip(
        fromDiffSet(levels.ts),
        fromDiffSet(levels.level),
        fromDiffSet(levels.min),
        fromDiffSet(levels.max),
        fromDiffSet(temps.level)
      ).map((a) => ({
        ts: a[0] * 1000,
        level: a[1],
        min: a[2],
        max: a[3],
        temp: a[4],
      }));
      rows = [["time", "level", "min", "max", "temp"]];
      for (let i in levels) {
        const d = levels[i];
        if (d.ts)
          rows.push([
            `"` + new Date(d.ts).toISOString() + `"`,
            d.level,
            d.min,
            d.max,
            d.temp,
          ]);
      }
    } else {
      levels = _.zip(
        fromDiffSet(levels.ts),
        fromDiffSet(levels.level),
        fromDiffSet(levels.min),
        fromDiffSet(levels.max)
      ).map((a) => ({ ts: a[0] * 1000, level: a[1], min: a[2], max: a[3] }));
      rows = [["time", "level", "min", "max"]];
      for (let i in levels) {
        const d = levels[i];
        rows.push([
          `"` + new Date(d.ts).toISOString() + `"`,
          d.level,
          d.min,
          d.max,
        ]);
      }
    }

    // let csvContent =
    //   "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    // var encodedUri = encodeURI(csvContent);
    let csvString = rows.map((e) => e.join(",")).join("\n");

    let csvData = new Blob([csvString], { type: "text/csv" });
    var csvUrl = URL.createObjectURL(csvData);
    // a.href = csvUrl;

    window.open(csvUrl);

    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchLevels = useCallback(
    async (id, zoom) => {
      let start = zoom[0].startOf("day").unix();
      let end = zoom[1].endOf("day").unix();

      let req_vatten = fetch(
        `https://api.vatten.carera.se/vatten_export/${id}/${parseInt(
          start
        )}/${parseInt(end)}/${granularity}`
      );
      // let req_vatten = fetch(`http://localhost:3037/vatten_export/${id}/${zoom[0]}/${zoom[1]}`);
      let response = await req_vatten;
      let buffer = await response.arrayBuffer();

      var content = new Uint8Array(buffer, 0, buffer.length);
      let levels = Levels.decode(content);

      return levels;
    },
    [granularity]
  );

  const fetchTemps = useCallback(
    async (id, zoom) => {
      if (!["1d", "12h", "3h"].includes(granularity)) return;
      let start = zoom[0].startOf("day").unix();
      let end = zoom[1].endOf("day").unix();

      let req_temp = fetch(
        `https://api.vatten.carera.se/temp_export/${id}/${parseInt(
          start
        )}/${parseInt(end)}/${granularity}`
      );
      // let req_temp = fetch(`http://localhost:3037/temp_export/${id}/${zoom[0]}/${zoom[1]}`);
      let response = await req_temp;
      let buffer = await response.arrayBuffer();

      var content = new Uint8Array(buffer, 0, buffer.length);
      let levels = Levels.decode(content);

      return levels;
    },
    [granularity]
  );

  function disabledDate(current) {
    // Can not select days before today and today
    return (
      current &&
      (current < new Date(span[0] * 1000) || current > new Date(span[1] * 1000))
    );
  }

  return (
    <div>
      <Modal
        title="Export"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {span && (
          <div>
            <p>Date range:</p>
            <RangePicker
              disabledDate={disabledDate}
              onChange={setRange}
              // defaultValue={range}
            />
          </div>
        )}
        <br />
        <div>
          <p>Resolution:</p>
          <Select
            defaultValue="1d"
            style={{ width: 120 }}
            onChange={setGranularity}
          >
            <Option value="1d">1 day</Option>
            {/* <Option value="12h">12 hours</Option>
            <Option value="3h">3 hours</Option> */}
            <Option value="1h">1 hour</Option>
            {/* <Option value="30m">30 minutes</Option>
            <Option value="10m">10 minutes</Option>
            <Option value="5m">5 minutes</Option> */}
            <Option value="1m">1 minute</Option>
            {/* <Option value="point">Every</Option> */}
          </Select>
        </div>
      </Modal>
      {zoom && location && (
        <Button onClick={showModal} style={{ float: "right" }}>
          Export
        </Button>
      )}
    </div>
  );
};

export default Exporter;
function fromDiffSet(ds) {
  let g = Math.pow(10, ds.precision || 0);

  return ds.data
    .reduce((a, b) => {
      let last = a.length > 0 ? a[a.length - 1] : 0;
      a.push(b + last);
      return a;
    }, [])
    .map((x) => x / g);
}
