// import { renderToString } from 'react-dom/server';
import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import "../Marker.css";

const Map = ({ location }) => {
    let [once, setOnce] = useState();;

    useEffect(() => {
        if (location)
            setPoint({ lng: location.lon, lat: location.lat, name: location.name });
    }, [location]);

    const getMapOptions = useCallback((maps) => {
        if (once)
            return once;

        let o = {
            streetViewControl: false,
            scaleControl: true,

            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE
                ]
            },

            zoomControl: true,
            clickableIcons: false
        };

        setOnce(o);
        return o;
    }, [once]);


    let [point, setPoint] = useState()

    const [mapHeight, setMapHeight] = useState(null);
    const [mapWidth, setMapWidth] = useState(null);
    const mapHolder = useCallback(node => {
        let f = () => {
            if (node !== null) {
                setMapWidth(node.offsetWidth);
                setMapHeight(2 * node.offsetWidth / 3);
            }
        };
        f();
        function handleResize() {
            f();
        }
        window.addEventListener('resize', handleResize)
    }, []);

    const mapContainerStyle = {
        width: mapWidth,
        height: mapHeight
    };



    return (
        <div ref={mapHolder}>
            {point && !isNaN(point.lng) && <div style={mapContainerStyle}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBzd7I6T7W35Ty8XR8FUpyET132N8pMZb4" }}
                    defaultCenter={point}
                    defaultZoom={17}
                    options={getMapOptions}
                >
                    <Marker
                        lat={point.lat}
                        lng={point.lng}
                    />
                </GoogleMapReact>
            </div>
            }
        </div>

    )
};

export default Map;

function Marker() {
    return <div className="marker">
        <div className='pin'></div>
        <div className='pulse'></div>
    </div>
}