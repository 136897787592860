import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb, Table, Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { TabPane } from 'rc-tabs';


const Organization = () => {
    let { id } = useParams();
    let [organization, setOrganization] = useState();

    const projectColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, o) => <Link to={`/projects/${o.id}`}>{text}</Link>,
        },
    ];
    const rentalColumns = [
        {
            title: 'Id',
            dataIndex: 'sensor_id',
            key: 'sensor_id',
        },
    ];
    const memberColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];

    const getOrganization = useCallback(async (id) => {
        let req = fetch(`https://api.vatten.carera.se/organizations/${id}`);
        // let req = fetch(`http://localhost:3037/projects/${id}`);
        let response = await req;
        let organization = await response.json();

        console.log(organization);

        setOrganization(organization);
    }, []);

    useEffect(() => {
        getOrganization(id);
    }, [id, getOrganization]);

    return (
        <div>
            <div className="breadcrumb">
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Organization</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="site-layout-content">

                <React.Fragment>
                    <h1>{organization && organization.name}</h1>

                    {organization &&
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Projects" key="1">
                                <Table pagination={false} columns={projectColumns} dataSource={organization.projects} rowKey="id" />
                            </TabPane>
                            <TabPane tab="Sensors" key="2">
                                <Table pagination={false} columns={rentalColumns} dataSource={organization.rentals} rowKey="sensor_id" />
                            </TabPane>
                            <TabPane tab="Users" key="3">
                                <Table pagination={false} columns={memberColumns} dataSource={organization.members} rowKey="user_id" />
                            </TabPane>
                        </Tabs>
                    }
                </React.Fragment>

            </div>

        </div>
    );
};

export default Organization;
