import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from "./redroc.svg";

import { Button, Layout } from "antd";
import Project from "./pages/Project";
import Organization from "./pages/Organization";
import Location from "./pages/Location";
import LogRocket from "logrocket";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

import moment from "moment";

moment.relativeTimeThreshold("m", 99);

LogRocket.init("redroc/vatten");
const { Header, Content, Footer } = Layout;
const App = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  let userHandler = useCallback(
    async (user) => {
      const token = await getIdTokenClaims();
      console.log(token.__raw);
      // let req = fetch(`https://api.vatten.carera.se/me`);
      let req = fetch(`https://api.vatten.carera.se/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.__raw}`,
        },
      });

      let response = await req;
      let me = await response.json();
      console.log(me);
      LogRocket.identify(me.id, {
        name: me.name,
        login: token.sub,
      });
    },
    [getIdTokenClaims]
  );

  useEffect(() => {
    if (user) {
      userHandler(user);
    }
  }, [user, userHandler]);

  return (
    <div className="App">
      <Router>
        <Layout className="layout">
          <Header>
            <Link to="/">
              <img src={logo} alt="Redroc" />
            </Link>
          </Header>
          <Content>
            <div className="content">
              <Switch>
                <Route path="/projects/:projectId/locations/:locationId">
                  <Location />
                </Route>
                <Route path="/organizations/:id">
                  <Organization />
                </Route>
                <Route path="/projects/:id">
                  <Project />
                </Route>
                <Route path="/">
                  {!isLoading && isAuthenticated ? (
                    <div>
                      <div>
                        <h2>{user.name}</h2>
                        <p>{user.email}</p>
                        <p>{user.sub}</p>
                      </div>
                      <Button
                        onClick={() =>
                          logout({ returnTo: window.location.origin })
                        }
                      >
                        Log Out
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={() => loginWithRedirect()}>Log In</Button>
                  )}
                </Route>
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", maxWidth: "800px" }}>
            Redroc Explorer ©2021 Created by Redroc AB
          </Footer>
        </Layout>
      </Router>
    </div>
  );
};

export default App;
