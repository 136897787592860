import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Breadcrumb, Table } from "antd";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

const ProjectMap = lazy(() => import("../components/ProjectMap"));
const renderLoader = () => <p>Loading</p>;

const Project = () => {
  let { id } = useParams();
  let [project, setProject] = useState();
  // const url = `/projects/${id}/locations/${o.id}`;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, o) => (
        <Link to={`/projects/${id}/locations/${o.id}`}>{text || ("[ " + o.sensor_id + " ]")}</Link>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (d) => d && `${d.toFixed(3)} m`,
    },
    {
      title: "Latest",
      dataIndex: "ts",
      key: "ts",
      render: (d) => d && moment(d).fromNow(),
    },
    // {
    //   title: "Resolution",
    //   dataIndex: "first",
    //   key: "first",
    //   render: (d) => (d ? "~20s" : "~1h"),
    // },
  ];

  const getProject = useCallback(async (id) => {
    let req = fetch(`https://api.vatten.carera.se/projects/${id}`);
    // let req = fetch(`http://localhost:3037/projects/${id}`);
    let response = await req;
    let project = await response.json();

    console.log("Project", project);

    setProject(project);
  }, []);

  useEffect(() => {
    getProject(id);
  }, [id, getProject]);

  return (
    <div>
      <div className="breadcrumb">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Project</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="site-layout-content">
        <React.Fragment>
          <h1>{project && project.name}</h1>

          {project && (
            <Table
              pagination={false}
              columns={columns}
              dataSource={project.locations}
              rowKey="id"
            />
          )}
          <br />

          {project &&
            project.locations &&
            project.locations.some((x) => x.lon) && (
              <div>
                <Suspense fallback={renderLoader()}>
                  <ProjectMap project={project} />
                </Suspense>
              </div>
            )}
        </React.Fragment>
      </div>
    </div>
  );
};

export default Project;
