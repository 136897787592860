// import { renderToString } from 'react-dom/server';
import React, { lazy, useCallback, useEffect, useState, Suspense } from "react";
import { Breadcrumb, Statistic, Row, Col, Space, Card } from "antd";

import { Link, useParams } from "react-router-dom";
import "../Marker.css";
import Exporter from "../components/Exporter";
import Map from "../components/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWater, faThermometerHalf } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const NewChartView = lazy(() => import("../components/ChartView"));
const OldChartView = lazy(() => import("../components/OldChartView"));
const renderLoader = () => <p>Loading</p>;

const Location2 = () => {
  let { projectId, locationId } = useParams();

  let [zoom, setZoom] = useState();
  let [mode, setMode] = useState();
  let [location, setLocation] = useState();
  let [range, setRange] = useState();

  const getLocation = useCallback(async (id) => {
    let req = fetch(`https://api.vatten.carera.se/locations/${id}`);
    // let req = fetch(`http://localhost:3037/locations/${id}`);
    let response = await req;
    let loc = await response.json();

    console.log("Location", loc);
    setLocation(loc);

    // if (loc.first) {
    //   setRange(loc);
    //   setMode("new");
    // } else setMode("old");

    setRange({first:0,last:Date.now()});
    setMode("new");

  }, []);

  useEffect(() => {
    if (locationId) {
      getLocation(locationId);
    }
  }, [locationId, getLocation]);

  return (
    <div>
      <div className="breadcrumb">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to={`/projects/${projectId}`}>Project</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="site-layout-content home">
        <h1>{location && (location.name || "[ " + location.sensor_id + " ]")}</h1>
        {location && (
          <Space direction="vertical">
            <Card>
              <Row gutter={16}>
                <Col span={24}>
                  <Statistic
                    title="Latest reading"
                    value={moment(location.ts).fromNow()}
                    // valueStyle={{ color: '#ffc14f' }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic
                    title="Level"
                    value={location.level}
                    precision={3}
                    valueStyle={{ color: "#6794dc" }}
                    prefix={<FontAwesomeIcon icon={faWater} />}
                    suffix="m"
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Temperature"
                    value={location.temp}
                    precision={1}
                    valueStyle={{ color: "#ffc14f" }}
                    prefix={<FontAwesomeIcon icon={faThermometerHalf} />}
                    suffix="°C"
                  />
                </Col>
              </Row>
            </Card>
            <Card title="Historic">
              {mode === "old" && (
                <Suspense fallback={renderLoader()}>
                  <OldChartView location={location} />
                </Suspense>
              )}
              {mode === "new" && (
                <div>
                  <Suspense fallback={renderLoader()}>
                    <NewChartView
                      location={location}
                      range={range}
                      onZoomChange={setZoom}
                    />
                  </Suspense>

                  <Exporter
                    location={locationId}
                    zoom={zoom}
                    span={[location.first, location.last]}
                  />
                </div>
              )}
            </Card>
            {location.lon && (
              <Card title="Map">
                <Map location={location} />
              </Card>
            )}
          </Space>
        )}
      </div>
    </div>
  );
};

export default Location2;
